var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticStyle:{"position":"sticky"},style:(_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm
      ? 'padding-top: 70px !important'
      : _vm.$vuetify.breakpoint.xs
      ? 'padding-top: 130px !important'
      : '')},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{class:_vm.$vuetify.breakpoint.xs ? 'pt-1 pb-0' : '',attrs:{"sm":"auto"}},[_c('v-toolbar-title',[_vm._v("Categoria cliente")])],1),_c('v-col',{class:_vm.$vuetify.breakpoint.xs ? 'pt-1' : '',attrs:{"cols":"12","sm":"5","md":"4","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.customerTypes,"item-text":"customerTypeName","item-value":"id","item-color":"primary darken-2","label":"Tipologia cliente","no-data-text":"Nessuna tipologia trovata","hide-details":"","light":"","dense":"","solo":"","flat":"","menu-props":{ offsetY: true }},on:{"change":_vm.onChangeCustomerTypes},model:{value:(_vm.customerTypeID),callback:function ($$v) {_vm.customerTypeID=$$v},expression:"customerTypeID"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","color":"primary darken-1","label":"Cerca","placeholder":"Cerca","append-icon":"mdi-magnify","outlined":""},on:{"change":_vm.getProductsPreview},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{key:_vm.show},_vm._l((_vm.productsPreview),function(p,idx){return _c('v-col',{key:idx,attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-lazy',{attrs:{"options":{
          threshold: 0.1,
        },"min-height":"330px"},model:{value:(p.isActive),callback:function ($$v) {_vm.$set(p, "isActive", $$v)},expression:"p.isActive"}},[_c('ll-product-card',{attrs:{"product":p,"isPreview":true}})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }