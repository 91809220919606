
import { Component, Vue } from "vue-property-decorator";
import { customerTypes } from "@/services/api/customer-type.service";
import { products } from "@/services/api/products.service";
import { ProductsCard } from "@/models/entities/products-card.interface";
import OverlayModule from "@/store/modules/overlay.module";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { CustomerType } from "@/models/entities/customer-type.interface";
const overlayModule = getModule(OverlayModule, store);

@Component
export default class Preview extends Vue {
  private search = "";
  private customerTypeID = 1;
  private customerTypes = [] as CustomerType[];
  private productsPreview = [] as ProductsCard[];
  private filtersMenu = false;
  private show = true;

  async created(): Promise<void> {
    await Promise.all([this.getCustomerTypes(), this.getProductsPreview()]);
  }
  private async getCustomerTypes(): Promise<void> {
    overlayModule.showOverlay();
    let ct = await customerTypes.getAllCustomerType();
    this.customerTypes = ct.data;
  }
  private async onChangeCustomerTypes(): Promise<void> {
    await Promise.all([this.getProductsPreview()]);
  }
  private async getProductsPreview(): Promise<void> {
    this.show = false;
    overlayModule.showOverlay();
    let pp = await products.getProductsPreview(
      this.customerTypeID,
      this.search
    );
    this.productsPreview = pp.data;
    this.show = true;
  }
}
